<!-- 购买弹窗 -->
<template>
  <div>
    <el-dialog
        width="30%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'购买':'购买'"
        @update:visible="updateVisible">

      <el-form
          ref="form"
          :model="form"
          label-width="100px">

        <!--<el-form-item label="现金金额：">-->
        <!--  <span style="font-size: 30px;font-weight: 700;color: #3f4155;">4800,00</span>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="充值方式：">-->
        <!--  <div>-->
        <!--    <div v-for="(item,index) in list" :key="index" class="xuanxiang" :class="{xuanxiang1:index==isShow}" @click="activeItem(item,index)">-->
        <!--      <div style="display: flex;align-items: center;">-->
        <!--        <img :src="item.img" alt="">-->
        <!--        <span>{{item.label}}</span>-->
        <!--      </div>-->
        <!--    </div>-->
        <!--  </div>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="资源包：">-->
        <!--  <div>-->
        <!--    <div v-for="(item,index) in dataList" :key="index" class="xuanxiang" :class="{xuanxiang1:index==isShow1}" @click="activeItem1(item,index)">-->
        <!--      <div style="display: flex;align-items: center;">-->
        <!--        <span>{{item.label}}</span>-->
        <!--      </div>-->
        <!--    </div>-->
        <!--  </div>-->
        <!--</el-form-item>-->

        <!--支付宝支付-->
        <div style="margin-bottom: 10px;">
          <span>单价: {{jiage}}</span>
        </div>
        <el-form-item :label="label">
          <el-input
            type="number"
            clearable
            :maxlength="9"
            @input="limitPhoneNum"
            v-model="form.numberofbranches"
            :placeholder="placeholder"/>
        </el-form-item>

        <!--微信支付-->
        <!--<div v-if="isShow === 0">-->
        <!--  <el-form-item label="充值金额：">-->
        <!--    <el-input-->
        <!--        clearable-->
        <!--        :maxlength="20"-->
        <!--        v-model="form.nickname"-->
        <!--        placeholder="请输入充值金额"/>-->
        <!--  </el-form-item>-->
        <!--</div>-->

        <!--转账支付-->
        <div v-if="isShow === 1">
          <el-form-item label="收款信息：">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入收款信息"/>
          </el-form-item>
          <el-form-item label="银行：">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入银行"/>
          </el-form-item>
          <el-form-item label="开户行：">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入开户行"/>
          </el-form-item>
          <el-form-item label="账号：">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入账号"/>
          </el-form-item>
          <el-form-item label="收款说明：">
            <el-input
                clearable
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 5}"
                placeholder="请输入收款说明"
                v-model="form.textarea2">
            </el-input>
          </el-form-item>
        </div>

      </el-form>

      <div slot="footer" v-if="isShow === 0">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="nextStep">确定
        </el-button>
      </div>
      <div slot="footer" v-if="isShow === 1">
        <el-button
            @click="updateVisible(false)">确认已汇款
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        center
        title="充值"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <span>
        <el-row :gutter="15">
          <el-col :span="12">
            <div style="display: flex;align-items: center;">
              <span style="font-size: 14px;font-weight: 400;color: #666666;margin-right: 15px;">现金金额</span>
              <span style="font-size: 30px;font-weight: 700;color: #3f4155;">4800,00</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="display: flex;align-items: center;">
              <span style="font-size: 14px;font-weight: 400;color: #666666;margin-right: 15px;">现金金额</span>
              <span style="font-size: 30px;font-weight: 700;color: #3f4155;">5700,00</span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 15px;">
          <div style="display: flex;">
            <span style="font-size: 14px;font-weight: 400;color: #666666;margin-right: 15px;">收款二维码</span>
            <img src="@/assets/images/home/erweima.png" alt="" style="width: 161px;height: 161px;">
          </div>
        </el-row>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fanhui">返 回</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import {resource_buy} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Number,
    price: Number
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: {
        numberofbranches:'',
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      list:[
        {
          img:require('../../../assets/images/home/gerenzhongxin/zhifuactive.png'),
          label:'微信支付'
        },
        {
          img:require('../../../assets/images/home/gerenzhongxin/zhuanzhang.png'),
          label:'转账支付'
        }
      ],
      isShow:0,

      isShow1:0,
      dataList:[
        {
          label:'10条/0.5元'
        },
        {
          label: '100条/4元'
        },
        {
          label: '1000条/30元'
        }
      ],

      dialogVisible: false,

      type:'',
      label:'',
      placeholder:'',
      jiage:'',

    };
  },
  watch: {
    // data() {
    //   if (this.data) {
    //     console.log(this.data)
    //     this.form = Object.assign({}, this.data);
    //     this.isUpdate = true;
    //   } else {
    //     this.form = {};
    //     this.isUpdate = false;
    //   }
    // }
  },
  mounted() {
    // if (this.data) {
    //     console.log(this.data)
    //     this.isUpdate = true;
    //   } else {
    //     this.form = {};
    //     this.isUpdate = false;
    //   }
    console.log(this.data)
    // :type => [note=通知短信资源、call=通话时长资源]
    if(this.data == 0){
      this.type = 'call'
      this.label = '电话充值';
      this.placeholder = '请输入购买的电话分钟数';
      this.jiage = this.price + '/分钟'
    }else if(this.data == 1){
      this.type = 'note';
      this.label = '短信充值';
      this.placeholder = '请输入购买的短信条数';
      this.jiage = this.price + '/条数'
    }else if(this.data == 2){
      this.type = 'privacy';
      this.label = '隐私号码数量';
      this.placeholder = '请输入购买的隐私号码数量';
      this.jiage = this.price + '/个'
    }else if(this.data == 3){
      this.type = 'coord';
      this.label = '虚拟司机数量';
      this.placeholder = '请输入购买的虚拟司机数量';
      this.jiage = this.price + '/个'
    }
  },

  methods: {
    // 限制输入字数
    limitPhoneNum(value){
      if (value.toString().length > 9) {
        this.form.numberofbranches = this.form.numberofbranches.toString().slice(0, 9)
      }
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    nextStep(){
      if(this.form.numberofbranches == ''){
        this.$message.error('请输入充值条数');
      }else {
        let data = {
          amount: this.form.numberofbranches,
          return_url: window.location.href
        }
        resource_buy(this.type,data).then(res => {
          if(res.code === 200){
            console.log(res)
            window.location.href = res.data.return;
            this.form.numberofbranches = '';
            this.$emit('update:visible', false);
          }else {
            this.$message.error(res.msg);
          }
        }).catch(e => {
          this.$message.error(e.msg);
        })
      }
      // this.dialogVisible = true;
      // this.$emit('update:visible', false);
    },

    fanhui(){
      this.dialogVisible = false;
      this.$emit('update:visible', true);
    },

    handleClose(){
      this.dialogVisible = false;
      this.$emit('update:visible', true);
    },

    //选择项事件
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      if(item.label === '微信支付'){
        this.list[0].img = require('../../../assets/images/home/gerenzhongxin/zhifuactive.png');
        this.list[1].img = require('../../../assets/images/home/gerenzhongxin/zhuanzhang.png');
      }else if(item.label === '转账支付'){
        this.list[0].img = require('../../../assets/images/home/gerenzhongxin/zhifu.png');
        this.list[1].img = require('../../../assets/images/home/gerenzhongxin/zhuanzhuangactive.png');
      }
    },

    activeItem1(item,index){
      this.isShow1 = index;
    },


  }
}
</script>

<style scoped lang="scss">
.xuanxiang{
  display: inline-block;
  padding: 5px 15px 5px 15px;
  border: 1px solid #e9eaf1;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #3f4155;
  margin-right: 20px;
  cursor: pointer;
  img{
    width: 16px;
    height: 12px;
    margin-right: 15px;
  }
}
.xuanxiang1{
  background: #FFF4E3;
  border-color: #ffd38e;
  color: #FF9B05;
}

</style>
